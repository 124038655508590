import "../../App.css";

import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";


import { useEffect, useState } from "react";
import { getAuthUser } from "../../service/mftc-api";


import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import NotFound from "./pages/notfound/NotFound";
import Component74Page from "./pages/Component74Page/Component74Page";
import Component89Page from "./pages/Component89Page/Component89Page";
import Component92Page from "./pages/Component92Page/Component92Page";
import Component93Page from "./pages/Component93Page/Component93Page";


function AppClient() {



  const [authUser, setAuthUser] = useState(getAuthUser());









  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);


  const Init = () => {

      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: fullscreen)").matches;

if (window.navigator.standalone || isStandalone) {
                    return  !authUser ? <Component74Page/>    : <Component74Page/>
                          } else {
                    return  !authUser ? <Component74Page/>    : <Component74Page/>
                          }
  };


  return (

      <BrowserRouter>
        <Routes>
          <Route path="" element={<Init />} />
          <Route path="/" element={<Init />} />
          {
            }<Route path="/landingpage" element={<Component74Page/>}/>
<Route path="/mentionslegales" element={<Component89Page/>}/>
<Route path="/contacts" element={<Component92Page/>}/>
<Route path="/aboutus" element={<Component93Page/>}/>
{
          }
          
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

  );
}

export default AppClient;



